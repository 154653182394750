import React from 'react'
import Header from "./header.js"
import Footer from "./footer.js"
import "../styles/thalhalla.sass"
import "../styles/thalhalla.scss"

const Layout = ({ children }) => {
  return (
  <>
    <Header/>
    <section>
      {children}
    </section>
    <Footer/>
  </>
  )
}

export default Layout
