import React from 'react'
import { Link } from "gatsby"

const Header = ({ children }) => {
  return (
<div className="hero is-info is-small">
  <div className="hero-head">
    <nav className="navbar">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item">
            <img src="http://thalhalla.com/static/hammerTime.1aca28c3.jpg" alt="Logo"/>
          </a>
          <span className="navbar-burger" data-target="navbarMenuHeroB">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarMenuHeroB" className="navbar-menu">
          <div className="navbar-end">
            <Link to="/" activeClassName={"is-active"} className="navbar-item">
              Home
            </Link>
            <Link to="/examples" activeClassName={"is-active"} className="navbar-item">
              Examples
            </Link>
            <Link to="/docs" activeClassName={"is-active"} className="navbar-item">
              Documentation
            </Link>
            <span className="navbar-item">
              <Link to="/github" activeClassName={"is-active"} className="button is-info is-inverted">
                <span className="icon">
                  <i className="fab fa-github"></i>
                </span>
                <span>Download</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <div className="hero-body">
    <div className="container has-text-centered">
    <p className="title">
    <img src="http://thalhalla.com/static/hammerTime.1aca28c3.jpg" alt="Logo"/>
    </p>
      <p className="title">
        Thallalla
      </p>
      <p className="subtitle">
        Welcome to the hall
      </p>
    </div>
  </div>

  <div className="hero-foot">
    <nav className="tabs is-boxed is-fullwidth">
      <div className="container">
        <ul>
          <li className="is-active">
            <a>Overview</a>
          </li>
          <li>
            <a>Modifiers</a>
          </li>
          <li>
            <a>Grid</a>
          </li>
          <li>
            <a>Elements</a>
          </li>
          <li>
            <a>Components</a>
          </li>
          <li>
            <a>Layout</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
  )
}

export default Header
