import React from "react"
import Layout from "../components/layout.js"

const DocsPage = () => {
  return (
    <Layout>
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-justify-content-left">
            <h2 className="title is-2">Level 2 heading</h2>
            <p className="content">
            Well, the way they make shows is, they make one show. That show's called a pilot. Then they show that show to the people who make shows, and on the strength of that one show they decide if they're going to make more shows. Some pilots get picked and become television programs. Some don't, become nothing. She starred in one of the ones that became nothing.
            </p>
          </div>
          <div className="column is-justify-content-right">
            <h2 className="title is-2">Level 2 heading</h2>
            <p className="content">
            The path of the righteous man is beset on all sides by the iniquities of the selfish and the tyranny of evil men. Blessed is he who, in the name of charity and good will, shepherds the weak through the valley of darkness, for he is truly his brother's keeper and the finder of lost children. And I will strike down upon thee with great vengeance and furious anger those who would attempt to poison and destroy My brothers. And you will know My name is the Lord when I lay My vengeance upon thee.
            </p>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  )
}

export default DocsPage
