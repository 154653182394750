import React from 'react'
import { Link } from "gatsby"

const Footer = ({ children }) => {
  return (
<div className="hero is-info is-small">
  <div className="hero-foot">
    <nav className="tabs is-boxed is-fullwidth is-primary">
      <div className="container is-primary">
        <ul>
          <li className="is-active">
            <a>Overview</a>
          </li>
          <li>
            <a>Modifiers</a>
          </li>
          <li>
            <a>Grid</a>
          </li>
          <li>
            <a>Elements</a>
          </li>
          <li>
            <a>Components</a>
          </li>
          <li>
            <a>Layout</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
  )
}

export default Footer
